<mat-toolbar class="header">
  <div class="header__left">
    <button mat-icon-button [attr.data-integrationtest-id]="'toggle-navi'" (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <a href="https://zealinstantgames.com/" target="_blank">
      <img src="assets/zeal-logo.svg" height="35" class="header__logo" alt="ZIG Logo" />
    </a>
  </div>
  <div class="header__right" *ngIf="authService.currentUser$ | async as currentUser">
    <div>Welcome, {{ currentUser.username }}!</div>
    <div
      >Operator: <strong>{{ currentUser.operator }}</strong></div
    >
  </div>
</mat-toolbar>

<mat-drawer-container autosize>
  <mat-drawer #sidenav mode="side" opened>
    <mat-nav-list *ngIf="authService.currentUser$ | async as currentUser">
      <ng-container *ngIf="currentUser.role === 'rng_user'">
        <a mat-list-item routerLink="/rng" routerLinkActive="active-link" data-integrationtest-id="navi-link-rng">
          <mat-icon>pin</mat-icon>
          <span>Zahlengenerator</span>
        </a>
      </ng-container>
      <ng-container *ngIf="currentUser.role !== 'rng_user'">
        <a
          mat-list-item
          routerLink="/billings"
          routerLinkActive="active-link"
          data-integrationtest-id="navi-link-billings"
        >
          <mat-icon>attach_money</mat-icon>
          <span>Billings</span>
        </a>
        <a mat-list-item routerLink="/rounds" routerLinkActive="active-link" data-integrationtest-id="navi-link-rounds">
          <mat-icon>list</mat-icon>
          <span>Rounds</span>
        </a>
        <a mat-list-item routerLink="/games" routerLinkActive="active-link" data-integrationtest-id="navi-link-games">
          <mat-icon>casino</mat-icon>
          <span>Games</span>
        </a>
        <a
          mat-list-item
          routerLink="/users"
          routerLinkActive="active-link"
          data-integrationtest-id="navi-link-users"
          *role="'admin'"
        >
          <mat-icon>people</mat-icon>
          <span>Users</span>
        </a>
        <a
          mat-list-item
          routerLink="/games-performance"
          routerLinkActive="active-link"
          data-integrationtest-id="navi-link-performance"
          *role="'admin'"
        >
          <mat-icon>bar_chart</mat-icon>
          <span>Games Performance</span>
        </a>
      </ng-container>
      <mat-list-item data-integrationtest-id="navi-link-logout" (click)="logout()">
        <mat-icon>lock</mat-icon>
        <span>Logout</span>
      </mat-list-item>
    </mat-nav-list>
  </mat-drawer>

  <mat-drawer-content class="content">
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
