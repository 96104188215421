import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { registerLocaleData } from '@angular/common';
import { appConfig } from './app/app.config';

if (environment.production) {
  enableProdMode();
}

async function main() {
  let data;

  let locale = window.navigator.language;

  switch (locale) {
    case 'de':
    case 'de-DE':
      data = await import('@angular/common/locales/de').then(m => m.default);
      break;

    default:
      locale = 'en-US';
      data = await import('@angular/common/locales/en').then(m => m.default);
      break;
  }

  registerLocaleData(data, locale);

  await bootstrapApplication(AppComponent, appConfig);
}

void main();
