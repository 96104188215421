import { HttpErrorResponse, type HttpInterceptorFn } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Logger } from '../utils/logger';

const logger = Logger.get('AuthInterceptor');

export const authInterceptor: HttpInterceptorFn = (request, next) => {
  const router = inject(Router);

  const authService = inject(AuthService);

  const matDialogService = inject(MatDialog);

  return next(request).pipe(
    catchError(error => {
      if (error instanceof HttpErrorResponse) {
        if ([401].indexOf(error.status) !== -1) {
          logger.info('Intercepted 401 error. Redirecting to auth route...');

          if (authService.isLoggedIn) {
            authService.logout();
          }

          matDialogService.closeAll();

          if (!router.url.startsWith('/auth')) {
            router.navigate(['auth'], {
              state: {
                redirectUrl: router.url.split('?')[0],
              },
            });
          }
        }
      }

      return throwError(() => new Error(error));
    })
  );
};
