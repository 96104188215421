import { Directive, Input, type OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Logger } from '../utils/logger';
import { AuthService } from './auth.service';

const logger = Logger.get('RoleDirective');

@Directive({ selector: '[role]', standalone: true })
export class RoleDirective implements OnInit {
  @Input() role!: string;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    logger.debug(`Check role: ${this.role}`);
    if (this.authService.role === 'admin') {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
