import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Logger } from '../utils/logger';
import { AuthService } from './auth.service';
import { catchError, map } from 'rxjs/operators';

const logger: Logger = Logger.get('AuthGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
    logger.debug('Check if allowed to navigate to: ', state.url);

    if (this.authService.isLoggedIn) {
      return true;
    }

    // probably reload so refresh auth
    return this.authService.auth().pipe(
      map(user => {
        logger.info('Authenticated as', user?.username);
        return user != null;
      }),
      catchError(err => {
        logger.warn('Failed to check if authenticated, redirecting to login page.');
        return of(this.router.parseUrl('/auth'));
      })
    );
  }
}
