import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter } from 'rxjs/operators';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  imports: [RouterOutlet],
})
export class AppComponent {
  constructor(
    private readonly router: Router,
    private readonly snackBar: MatSnackBar
  ) {
    this.router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe(() => this.snackBar.dismiss());
  }
}
