import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { RouterLink, RouterOutlet, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { RoleDirective } from '../auth/role.directive';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'main-wrapper',
  templateUrl: './main-wrapper.component.html',
  styleUrls: ['./main-wrapper.component.scss'],
  imports: [
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    AsyncPipe,
    NgIf,
    MatListModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    MatButtonModule,
    RoleDirective,
  ],
})
export class MainWrapperComponent {
  constructor(protected readonly authService: AuthService) {}

  public logout() {
    this.authService.logout();
  }
}
