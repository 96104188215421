import { type Routes } from '@angular/router';
import { MainWrapperComponent } from './main-wrapper/main-wrapper.component';
import { AuthGuard } from './auth/auth.guard';
import { GamesComponent } from './games/games.component';

export const ROUTES: Routes = [
  {
    path: 'auth',
    loadComponent: () => import('./auth/auth.component').then(m => m.AuthComponent),
  },
  {
    path: '',
    component: MainWrapperComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'games',
        pathMatch: 'full',
      },
      {
        path: 'billings',
        loadComponent: () => import('./billings/billings-page.component').then(m => m.BillingsPageComponent),
      },
      {
        path: 'rounds',
        loadComponent: () => import('./rounds/rounds.component').then(m => m.RoundsComponent),
      },
      {
        path: 'games',
        // NOTE: Load directly as it is the default route.
        component: GamesComponent,
      },
      {
        path: 'games/sites/:site',
        loadComponent: () => import('./games/games.component').then(m => m.GamesComponent),
      },
      {
        path: 'users',
        loadComponent: () => import('./users/users.component').then(m => m.UsersComponent),
      },
      {
        path: 'games-performance',
        loadComponent: () =>
          import('./games-performance/games-performance.component').then(m => m.GamesPerformanceComponent),
      },
      {
        path: 'rng',
        loadComponent: () => import('./rng/rng.component').then(m => m.RngComponent),
      },
    ],
  },
];
